<template>
  <Day day="15.12.2022">
    <div slot="page-content">
      <h3>Interstellar</h3>
      <p>
        Merry Christmas Companion! Special of the day: Multichain Launch! You voted, our DAO executed. 
        Today is the day. Enjoy Aboat going Multichain!
      </p>
      <img
          width="300"
          src="https://talkaboat.online/assets/images/talkaboat_light.png"
          alt=""
        >
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day15',
  components: {
    Day
  }
}
</script>
